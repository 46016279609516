import router from '../router/'
import { storage } from '../libs'

const onRequest = {
    onFulfilled: async (config) => {
        //passa o token antes da requisição
        config['headers']['token'] = storage.getToken()
        return config;
    },
    onRejected: (error) => {
        return Promise.reject(error);
    }
};
const onResponse = {
    onFulfilled: (response) => {
        return response;
    },
    onRejected: (error) => {
        //Recebeu uma resposta do servidor.
        if (error.response) {
            let httpCodeError = error.response.status;
            if (httpCodeError > 0) {
                let pathUrl = window.location.pathname;
                let isPublic = pathUrl.includes("login") || pathUrl.includes("logout");
                switch (httpCodeError) {
                    case 401:
                        if (!isPublic) {
                            alert("Acesso negado");
                            router.push('/login')
                        }
                        break;
                    case 404:
                        //Página de destino não existe
                        router.push('/404')
                        break;
                    case 403:
                        if (!isPublic) {
                            alert("Sem permissão");
                            router.push('/login')
                        }
                        break;
                    case 405:
                        if (!isPublic) {
                            alert('Método ou URL não existem');
                        }
                        break;
                }

                return Promise.reject(error);
            }
        }else{
            alert(`O servidor respondeu com erro. Possíveis causas: Arquivo de upload muito grande, permissão de CORS negada, Sessão perdida.`);
            return Promise.reject(error);
        }
    }
};

export {onRequest, onResponse }
