import { storage } from "../libs";
export default async (to, from, next) => {
  const currentToken = storage.getToken();
  let pattern =/([a-z0-9]{14,})-([a-z0-9]{14,})-([a-z0-9]{14,})/g;
  window.document.title = 'RBX.ext ' + to.meta.title || '';
  if (!pattern.test(currentToken) && to.meta.private) {
    next({ path: "/login" });
  } else {
    next();
  }
};
