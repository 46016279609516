export default [
    {
        path: '/*',
        name: 'Error',
        meta: {
            title: '404'
        },
        component: () => import('./Error404.vue'),
    }
]
