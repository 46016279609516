import Vue from 'vue'
import routes from './routes'
import VueRouter from 'vue-router'
import beforeEach from './before-each'

Vue.use(VueRouter);

const router = new VueRouter({
    hash: false,
    mode: 'history',
    routes,
    scrollBehavior: () => ({ y: 0 }),
    linkExactActiveClass: 'nav-item active',
    base: '/'
});

 router.beforeEach(beforeEach)
// router.afterEach(afterEach)

export default router
