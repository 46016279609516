const storage = {
    getToken: () => {
       return sessionStorage.getItem("token")
    },
    setToken: (token) => {
        return sessionStorage.setItem("token",token)
    },
    clearToken: () => {
        return sessionStorage.removeItem("token")
    }
}

export { storage }
