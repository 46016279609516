import Vue from 'vue'
import axios from 'axios'
import address from "./address";
import {onResponse, onRequest} from './interceptors'

//Cria uma instancia do axios
const http = axios.create({
    baseURL: address.baseURL,
    timeout: 60000,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Max-Age': '600',
        'Cache-Control': 'no-cache',
        "Accept": "application/json",
        "Content-Type": "application/json",
    },
    crossdomain: true
});

http.interceptors.request.use(onRequest.onFulfilled, onRequest.onRejected);
http.interceptors.response.use(onResponse.onFulfilled, onResponse.onRejected);

Vue.prototype.$http = http;

export { http, address }

export default http;
