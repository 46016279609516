export default [
    {
        path: '/login',
        name: 'Login',
        meta: {
            title: 'Entrar'
        },
        component: () => import('./Login.vue'),
    }
]
