<template>
  <div id="app">
    <!-- ======= Top Bar ======= -->
    <div id="topbar" class="d-none d-lg-flex align-items-center fixed-top">
      <div class="container d-flex">
        <div class="contact-info mr-auto">
          <strong>LIG16</strong> - RBX Integração
        </div>
        <!-- <div class="social-links">
          <a href="#" class="twitter"><i class="icofont-twitter"></i></a>
          <a href="#" class="facebook"><i class="icofont-facebook"></i></a>
          <a href="#" class="instagram"><i class="icofont-instagram"></i></a>
          <a href="#" class="skype"><i class="icofont-skype"></i></a>
          <a href="#" class="linkedin"><i class="icofont-linkedin"></i></a>
        </div> -->
      </div>
    </div>

    <!-- ======= Header ======= -->
    <header id="header" class="fixed-top">
      <div class="container d-flex align-items-center">
        <h1 class="logo mr-auto"><router-link to="/"></router-link>Contratos</h1>
        <!-- Uncomment below if you prefer to use an image logo -->
        <!-- <a href="index.html" class="logo mr-auto"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->

        <nav class="nav-menu d-lg-block">
          <ul>
              <router-link to="/">Últimos Clientes</router-link>
<!--            <li class=""><a href="index.html">Home</a></li>-->
<!--            <li><a href="#about">About</a></li>-->
<!--            <li><a href="#services">Services</a></li>-->
<!--            <li><a href="#departments">Departments</a></li>-->
<!--            <li><a href="#doctors">Doctors</a></li>-->
<!--            <li class="drop-down">-->
<!--              <a href="">Drop Down</a>-->
<!--              <ul>-->
<!--                <li><a href="#">Drop Down 1</a></li>-->
<!--                <li class="drop-down">-->
<!--                  <a href="#">Deep Drop Down</a>-->
<!--                  <ul>-->
<!--                    <li><a href="#">Deep Drop Down 1</a></li>-->
<!--                    <li><a href="#">Deep Drop Down 2</a></li>-->
<!--                    <li><a href="#">Deep Drop Down 3</a></li>-->
<!--                    <li><a href="#">Deep Drop Down 4</a></li>-->
<!--                    <li><a href="#">Deep Drop Down 5</a></li>-->
<!--                  </ul>-->
<!--                </li>-->
<!--                <li><a href="#">Drop Down 2</a></li>-->
<!--                <li><a href="#">Drop Down 3</a></li>-->
<!--                <li><a href="#">Drop Down 4</a></li>-->
<!--              </ul>-->
<!--            </li>-->
<!--            <li><a href="#contact">Contact</a></li>-->
          </ul>
        </nav>
        <!-- .nav-menu -->

        <router-link to="/novo" class="appointment-btn scrollto"><b-icon-plus></b-icon-plus> Novo Documento</router-link>

      </div>
    </header>
    <!-- End Header -->

 <section id="hero" class="d-flex align-items-center">

  </section><!-- End Hero -->

  <main id="main">
      <router-view></router-view>
  </main>

  </div>
</template>

<script>
export default {
  name: "App",
};
</script>
