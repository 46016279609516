const tryError = (e) => {
   const resp = e.response || {};

   return {
       data: resp.data || {},
       code: resp.status || 0,
   }

};

export { tryError }
