export default [
    {
        path: '/',
        name: 'Início',
        meta: {
            private: true,
            title: 'Contratos'
        },
        component: () => import('./Home.vue'),
    }
]
