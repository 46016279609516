export default [
    {
        path: '/novo/:code?',
        name: 'Novo Documento',
        meta: {
            private: true,
            title: 'Novo documento'
        },
        component: () => import('./NewDocument.vue'),
    },
    {
        path: '/documento',
        name: 'Visualização de Documento',
        meta: {
            private: true,
            title: 'Documentos'
        },
        component: () => import('./View.vue'),
    }
]
