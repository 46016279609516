import { default as home } from '../pages/home/routes.js'
import { default as document } from '../pages/document/routes.js'
import { default as login } from '../pages/login/routes.js'
import { default as error } from '../pages/error/routes.js'

export default [
    ...home,
    ...document,
    ...login,
    ...error
]
