import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "./assets/style/main.scss"
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Fragment from "./components/Fragment/Fragment"
Vue.use(require('vue-moment'))
import "./api"

Vue.config.productionTip = false

// Install BootstrapVue
Vue.use(BootstrapVue)

// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.component('fragment', Fragment)

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
